<template>
  <div  id="footer">
    <div class="acea-row row-middle">
      <router-link class="item" to="/Tasting/Index">
        <div class="iconfont iconshouye"></div>
        <div>首页</div>
      </router-link>
      <router-link class="item" to="/Tasting/BrandCenters">
        <div class="iconfont iconkongxinxingxing"></div>
        <div>品牌中心</div>
      </router-link>
      <!-- <a href="javascript:void(0);" class="item release" @click.prevent="onSelect('/Tasting/wine/Release')">
        <div class="iconfont iconxiangji border-color font-color"></div>
        <div></div>
      </a>
      <ActionSheet v-model="show" :actions="actions" description="选择您要发布的内容" /> -->
      <!-- <a href="javascript:void(0);" class="item" v-if="switche==0"  @click.prevent="clickswitchTab('/pages/index/index')">
        <div class="iconfont icongouwuche"></div>
        <div>商城</div>
      </a> -->
      <!-- <a href="javascript:void(0);" class="item" @click.prevent="onSelect('/Tasting/wine/list?brand_id=3')">
        <div class="iconfont iconcaidan-" ></div>
        <div>品鉴列表</div>
      </a> -->
      <!-- <a href="javascript:void(0);" class="item" v-if="switche==0" @click.prevent="clickswitchTab('/pages/user/user')">
        <div class="iconfont iconwode"></div>
        <div>我的</div>
      </a> -->
      <!-- <a href="javascript:void(0);" class="item" v-else @select="onSelect" > -->
      <!-- <a href="javascript:void(0);" class="item"  @click.prevent="onSelect('/Tasting/user')">
        <div class="iconfont iconwode"></div>
        <div>我的</div>
      </a> -->
    </div>
    <div style="text-align: center;"><a style="color: #dfdfdf;" href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow" title="工信部备案">粤ICP备12007614号</a></div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
import { ActionSheet, Toast } from 'vant';
import {miniSwitch} from "@api/tasting";
export default {
  name: "Footer",
  props: {},
  components: {
      ActionSheet
  },
  data: function() {
    return {
      show: false,
      switche:1,
      actions: [
        { 
          name: '杯酒论人生',
          url: '/Tasting/wine/Release',
        },
        // { 
        //   name: '专业品鉴',
        //   url: '/Tasting/PTasting/Release',
        // },
      ],
      footerList: [
        {
          name: "首页",
          icon1: "iconshouye",
          icon2: "icon-shouye",
          url: "/"
        },
        {
          name: "酒庄品牌",
          icon1: "iconfenlei",
          icon2: "icon-yingyongchengxu",
          url: "/category"
        },
        {
          name: "",
          icon1: "iconpaizhao",
          icon2: "icon-yingyongchengxu",
          url: "/category"
        },
        // {
        //   name: "商城",
        //   icon1: "icongouwuche",
        //   icon2: "icon-caigou",
        //   url: "/cart"
        // },
        {
          name: "我的",
          icon1: "iconwode",
          icon2: "icon-yonghu",
          url: "/user"
        }
      ]
    };
  },
  mounted: function() {
    this.setSwitche();
  },
  methods: {
    onSelect(item) {
      // Toast({
      //     message: '努力开发中，敬请期待...',
      //     forbidClick: true,
      // });
      this.show = false;
      this.$router.push({ path: item });
    },
    release: function () {
      this.show = true;
    },
    clicknavigateTo: function(url) {
      wx.miniProgram.navigateTo({url: url})
    },
    clickswitchTab: function(url) {
      wx.miniProgram.switchTab({url: url})
    },
    setSwitche:function(){
        miniSwitch({
            store_id:this.$store.state.app.storeid
        }).then(res=>{
          this.switche = res.data.type;
          console.log(res.data.type);
        }).catch(err => {

        });
    }
  }
};
</script>
<style scoped lang="scss">
  #footer{z-index:100;height: 1.3rem;}
  .release{
    position: relative;
    .iconfont{
      border-radius: 2rem;width: 1rem;height: 1rem!important;line-height: 0.9rem!important;position: absolute;top:-0.8rem;left: 50%;margin-left: -0.5rem;background: #ffffff;border-width: 2px!important;
    }
  }
</style>
